import React, { Suspense, lazy } from 'react';
import LoadingSpinner from "../components/LoadingSpinner";

const simulateLazyLoad = (importFunc) => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(importFunc()), 1000); // Simulasi delay 1 detik
  });
};

// Lazy loading untuk komponen
const Navbar = lazy(() => simulateLazyLoad(() => import("../components/Navbar")));
const Beranda = lazy(() => simulateLazyLoad(() => import('../components/Beranda')));
const Collaboration = lazy(() => simulateLazyLoad(() => import('../components/Collaboration')));
const PaymentLink = lazy(() => simulateLazyLoad(() => import('../components/PaymentLink')));
const Disbursement = lazy(() => simulateLazyLoad(() => import('../components/Disbursement')));
// const Remittance = lazy(() => simulateLazyLoad(() => import('../components/Remittance')));
// const Features = lazy(() => simulateLazyLoad(() => import('../components/Features')));
const Solusi = lazy(() => simulateLazyLoad(() => import('../components/Solusi')));
const OperasionalBisnis = lazy(() => simulateLazyLoad(() => import('../components/OperasionalBisnis')));
const TentangKami = lazy(() => simulateLazyLoad(() => import('../components/TentangKami')));

const Footer = lazy(() => simulateLazyLoad(() => import('../components/Footer')));


function HomeLandingPage() {
  return (
    <div>
      <Suspense fallback={<LoadingSpinner />}>
        <Navbar />
        <Beranda />
        <Collaboration />
        <PaymentLink />
        <Disbursement />
        {/* Remittance /> */}
        {/* Features /> */}
        <Solusi />
        <OperasionalBisnis />
        <TentangKami />
        <Footer />
      </Suspense>
    </div>
  );
}

export default HomeLandingPage;

