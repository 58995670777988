import HomeLandingPage from './pages/HomeLandingPage';
import FAQ from "./pages/FAQ"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React from "react";
import PrivacyNote from './pages/PrivacyNote';
import Facebook from './tracking/Facebook';

function App() {
  return (
    <Router>
      <Facebook/>
      <Routes>
        <Route path='/' element={<HomeLandingPage />} />
        <Route path='/FAQ' element={<FAQ />} />
        <Route path='/privacy-note' element={<PrivacyNote />} />
      </Routes>
    </Router>
  );
}

export default App;