import { lazy, Suspense } from 'react';
import LoadingSpinner from '../components/LoadingSpinner';

const simulateLazyLoad = (importFunc) => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(importFunc()), 1000); // Simulasi delay 1 detik
    });
};

const ContentPrivacyNote = lazy(() => simulateLazyLoad(() => import('../components/ContentPrivacyNote')));
const HeaderPage = lazy(() => simulateLazyLoad(() => import('../components/HeaderPage')));
const Navbar = lazy(() => simulateLazyLoad(() => import("../components/Navbar")));
const Footer = lazy(() => simulateLazyLoad(() => import('../components/Footer')));


export default function PrivacyNote() {
  return (
    <div>
        <Suspense fallback={<LoadingSpinner />}>
            <Navbar />
            <HeaderPage title="Kebijakan Privasi"/>

            <div className='container mx-auto py-10 px-10'>
                <ContentPrivacyNote />
            </div>
            <Footer />
        </Suspense>
    </div>
  )
}
