import React, { Suspense, lazy } from 'react';
import LoadingSpinner from "../components/LoadingSpinner";
import Accordion from "../components/Accordion";


const simulateLazyLoad = (importFunc) => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(importFunc()), 1000); // Simulasi delay 1 detik
    });
  };

const Navbar = lazy(() => simulateLazyLoad(() => import("../components/Navbar")));
const Footer = lazy(() => simulateLazyLoad(() => import('../components/Footer')));
const HeaderPage = lazy(() => simulateLazyLoad(() => import('../components/HeaderPage')));

function FAQ () {
    return (
        <div>
            <Suspense fallback={<LoadingSpinner />}>
                <Navbar/>
                <HeaderPage title="Frequently Asked Questions"/>
                <div className='relative p-10 m-10 rounded-2xl bg-slate-200'>
                    <Accordion/>
                </div>
                <Footer/>
            </Suspense>
        </div>
    );
}

export default FAQ;